import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import "./Job.css"
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { useIsDirectResult } from "../../../context/isDirectMatchingResult";
import TableMatches from "../Match/Match";
import LoaderComp from "../../../loader/Loader";
import Sidebar from "../Sidebar/Sidebar";
import { format } from "date-fns";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import { useIsMatchingRealTime } from "../../../context/isMatchingRealTime";
import { useMsal } from "@azure/msal-react";

function TableJobs() {
  const [jobs, setJobs] = useState([])
  const [announcementId, setOffer] = useState("");
  const [row, setRow] = useState([]);
  const [headers, setHeaders] = useState({});
  const { isDirectResult, toggleView } = useIsDirectResult();
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [userId] = useState(process.env.REACT_APP_DEFAULT_USER);
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const { isMatchingRealTime, toggleViewReal } = useIsMatchingRealTime();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkList, setCheckList] = useState<any[]>([])
  const [done, setDone] = useState<any[]>([])

  const { accounts } = useMsal();


  const [activeContent, setActiveContent] = useState(
    isMatchingRealTime ? "realtime" : "normal"
  );

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const switchContent = () => {
    setActiveContent((prevContent) =>
      prevContent === "normal" ? "realtime" : "normal"
    );
    toggleViewReal();
    setIsDropdownOpen(false);
  };

  const setResult = (announcement_id: any, row:any) => {
    setOffer(announcement_id)
    setRow(row);
    setHeaders(Object.keys(row))
    toggleView(false);
  }
  interface Job {
    announcement_id: string;
    announcement_type: string;
    business_line: string;
    offer_number: string;
    work_order_number: string;
    branch: string;
    reception_date: Date;
    contract_beginning_date: Date;
    contract_duration: number;
    professional_category: string;
    job_owner: string;
    client_desc: String;
    job_title: string;
    work_city: string;
    skills: string;
    educations: string;
    languages: string;
    cards: string;
    work_province: string;
    url: string;
    Categoria_protetta: string;
  }

  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () => [
      {
        accessorKey: 'announcement_id',
        header: 'Job ID',
        size: 100
      },
      {
        accessorKey: 'business_line',
        header: 'Tipo di Contratto',
        size: 25
      },
      {
        accessorKey: 'announcement_type',
        header: 'Tipologia',
        size: 150
      },
      {
        accessorKey: 'professional_category',
        header: 'Categoria professionale',
        size: 150
      },
      {
        accessorKey: 'job_title',
        header: 'Job title',
        size: 150
      },
      {
        accessorKey: 'client_desc',
        header: 'Cliente',
        size: 150
      },
      {
        accessorKey: 'work_city',
        header: 'Città di lavoro',
        size: 150
      },
      {
        accessorKey: 'branch',
        header: 'CDC',
        size: 25
      },
      {
        accessorKey: 'job_owner',
        header: 'Job Owner',
        size: 150
      },
      {
        accessorKey: 'reception_date',
        header: 'Data ricezione/pubblicazione',
        Cell: ({ row }) => format(new Date(row.original.reception_date), "yyyy-MM-dd"),
        size: 25
      },
      {
        accessorKey: 'skills',
        header: 'Skill richieste',
        size: 25
      },
      {
        accessorKey: 'educations',
        header: 'Education richiesta',
        size: 150
      },
      {
        accessorKey: 'languages',
        header: 'Lingue richieste',
        size: 150
      },
      {
        accessorKey: 'cards',
        header: 'Patenti richieste',
        size: 150
      },
      {
        accessorKey: 'offer_number',
        header: 'Numero Offerta',
        size: 25
      },
      {
        accessorKey: 'work_order_number',
        header: 'Numero Ordine',
        size: 25
      },
      {
        accessorKey: 'contract_beginning_date',
        header: 'Data inizio contratto',
        Cell: ({ row }) => format(new Date(row.original.contract_beginning_date), "yyyy-MM-dd"),
        size: 100
      },
      {
        accessorKey: 'contract_duration',
        header: 'Durata contratto',
        size: 100
      },
      {
        accessorKey: 'work_province',
        header: 'Provincia di lavoro',
        size: 150
      },
      {
        accessorKey: 'url',
        header: 'Link pagina offerta',
        Cell: ({ row }) => <a href={row.original.url}>{row.original.url}</a>,
        size: 150
      },
      {
        accessorKey: 'Categoria_protetta',
        header: 'Categoria protetta',
        size: 150
      },
    ],
    []
  )

  useEffect(() => {
    toggleView(true);
    fetch(`/api/databricks/jobread?username=${accounts[0]['username']}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
      .then(response => response.json())
      .then(data => setJobs(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [])

  const data: Job[] = jobs

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    initialState: { density: 'compact' },
    enableStickyHeader: true,
    localization: {'actions':'Suggerimento'},
    muiTableContainerProps: {sx: { maxHeight: '500px' } },
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <div>
      <button type="submit" className="button-action-dir" onClick={() => setResult(row.original.announcement_id,row.original)}>
        <h4>MATCHING</h4>
      </button>
      </div>

  ),
  });

  return (
    <div>
      {isDirectResult ?
      <div style={{textAlign:"center"}}>
        <div>
            <div id="menu-toggle-mobile">
              <div
                className={`container-toggle-dropdown mobile ${
                  isDropdownOpen ? "active" : ""
                }`}
                onClick={toggleDropdown}
              >
                <div>
                  {activeContent === "realtime" ? (
                    <>
                      <h4 className="m0">Opportunità aperte</h4>
                      <p className="m0">Cerca tra le opportunità disponibili</p>
                    </>
                  ) : (
                    <>
                      <h4 className="m0">Definisci l’offerta di lavoro</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </>
                  )}
                </div>
                <img
                  width={12}
                  src={isDropdownOpen ? chevronUp : chevronDown}
                  alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
                  className="chevron-icon"
                />
              </div>
              {isDropdownOpen && (
                <div className="dropdown-menu-mobile">
                  {activeContent === "normal" ? (
                    <div onClick={switchContent}>
                      <h4 className="m0">Opportunità aperte</h4>
                      <p className="m0">Cerca tra le opportunità disponibili</p>
                    </div>
                  ) : (
                    <div onClick={switchContent}>
                      <h4 className="m0">Definisci l’offerta di lavoro</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          <div>
        </div>
          {(jobs.length > 0 || timeElapsed) ? 
            <div>
              <Sidebar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} setJ={setJobs} jobs={jobs} timeE={setTimeElapsed} userId={userId} />
              <div className="back">
                <MaterialReactTable 
                table={table}
                />
              </div>
              <div className="spacing-div">
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
            </div> : <div className="div-center">
            <div>
              <h3> Caricamento Offerte</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
        </div>}
        </div>
      </div > 
      : 
      <div>
        <TableMatches announcement_id={announcementId} row={row} headers={headers} userId={userId} checkList={checkList} setCheckList={setCheckList} done={done} setDone={setDone}></TableMatches>
      </div>
      }
    </div>
  )

}

export default TableJobs;
