import { createContext, useState, useContext, ReactNode } from "react";
import React from "react";

interface IsReverseResultContextType {
  isReverseResult: boolean;
  toggleView: (bool: boolean) => void;
}

const IsReverseResultContext = createContext<
IsReverseResultContextType | undefined
>(undefined);

export const useIsReverseResult = () => {
  const context = useContext(IsReverseResultContext);
  if (!context) {
    throw new Error(
      "useIsReverseResult must be used within an IsReverseResultProvider",
    );
  }
  return context;
};

export const IsReverseResultProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_REVERSE_RESULT =
    process.env.REACT_APP_IS_REVERSE_RESULT_DEFAULT ?? "true";
  const [isReverseResult, setIsReverseResult] = useState(
    IS_REVERSE_RESULT === "true",
  );

  const toggleView = (bool: boolean) => {
    setIsReverseResult(bool);
  };

  return (
    <IsReverseResultContext.Provider value={{ isReverseResult, toggleView}}>
      {children}
    </IsReverseResultContext.Provider>
  );
};
