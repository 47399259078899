import "./DirectMatching.css";
import TableJobs from "./Job/Job";
import { useIsMatchingRealTime } from "../../context/isMatchingRealTime";
import RealTime from "./RealTime/RealTime";

const DirectMatching = (props:any) => {
  const { isMatchingRealTime } = useIsMatchingRealTime();

  return (
    <div>
      <div className="text-center">
          <h1 className="mb0 desktop">GPT Talent <span style={{ color: "red" }}> Finder </span></h1>
          <p className="mt0 desktop">
          </p>
      </div>
      {isMatchingRealTime? <TableJobs></TableJobs> : <RealTime ></RealTime>}
    </div>
  );
}

export default DirectMatching;
