import { useEffect, useState } from "react";
import Anagrafica from "./Buttons/Anagrafica";
import Ordini from "./Buttons/Ordini";
import Interviste from "./Buttons/Interviste";
import CV from "./Buttons/CV";
import "./CardDetails.css";
import { useIsDirectResult } from "../../../../context/isDirectMatchingResult";

function CardDetails(props: any) {
    const [anagrafica, setAnagrafica] = useState(false);
    const [ordini, setOrdini] = useState(false);
    const [interviste, setInterviste] = useState(false);
    const [cv, setCv] = useState(false);
    const { toggleView } = useIsDirectResult();

    const getAnagrafica = async() => {
        setAnagrafica(true);
        setOrdini(false);
        setInterviste(false);
        setCv(false);
    }

    const getOrdini = async() => {
        setAnagrafica(false);
        setOrdini(true);
        setInterviste(false);
        setCv(false);
    }

    const getInterviste= async() => {
        setAnagrafica(false);
        setOrdini(false);
        setInterviste(true);
        setCv(false);
    }

    const getCv = async() => {
        setAnagrafica(false);
        setOrdini(false);
        setInterviste(false);
        setCv(true);
    }

    useEffect(() => {
        setAnagrafica(true);
        setOrdini(false);
        setInterviste(false);
        setCv(false);
    }, [])

    const backDetails = () => {
        props.setUrlPDF("");
        props.setDetails(false);
    }

    const getBack = () => {
        toggleView(true);
    }

    return (
    <div>
        <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-home bx-sm bx-border' id='home-btn-4' onClick={getBack}>
                    <span className='home-btn-text-2'>
                    Torna alla schermata Home
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backDetails}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
        <div className="container-auto">
            <div className="center-div">
                <button className="cta-red-mat" onClick={getAnagrafica}>
                                {"Anagrafica"}
                </button>
                <button className="cta-red-mat" onClick={getOrdini}>
                                {"Storico Ordini"}
                </button>
                <button className="cta-red-mat" onClick={getInterviste}>
                                {"Storico Interviste"}
                </button>
                <button className="cta-red-mat" onClick={getCv}>
                                {"CV Candidato"}
                </button>
            </div>
            
            {(anagrafica)?<Anagrafica codFisc={props.codFisc} jobId={props.jobId}></Anagrafica>:
            <div>
                {(ordini)?<Ordini codFisc={props.codFisc}></Ordini>:
                <div>
                    {(interviste)?<Interviste codFisc={props.codFisc}></Interviste>:
                    <div>
                        {(cv && <CV cvs={props.cvs} setCvs={props.setCvs} codFisc={props.codFisc} urlPDF={props.urlPDF} setUrlPDF={props.setUrlPDF} userId={props.userId} isAI = {props.isAI} setIsAI = {props.setIsAI}></CV>)}
                    </div>
                }
                </div>
            }
            </div>
            }
        </div>
    </div>
    )
}

export default CardDetails;