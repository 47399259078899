import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table'
import './Ordini.css'
import LoaderComp from '../../../../../loader/Loader'
import { useEffect, useMemo, useState } from 'react'
import { _get } from '../../../../../../apiClient'
import { format } from 'date-fns'

function Ordini(props:any) {
    const [ordini, setOrdini] = useState([])
    const [timeE, setTimeE] = useState(false)

    const getOrd = () => {
      fetch(`/api/databricks/ordini?associate_id=${props.codFisc}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(data) {
          if (data.length === 0) {
            setTimeE(true);
          }
          setOrdini(data)
        })
      }

      useEffect(() => {
        getOrd();
      }, [])

    interface Ordini {
        Step_pipeline: string;
        assignation_date: Date;
        Business_Line: string;
        branch_desc: string;
        professional_category_desc: string;
        subsector_desc: string;
        sector_desc: string;
        client_desc: string;
      }
    
      const columns = useMemo<MRT_ColumnDef<Ordini>[]>(
        () => [
          {
            accessorKey: 'Step_pipeline',
            header: 'Step Pipeline',
            size: 100
          },
          {
            accessorKey: 'assignation_date',
            header: 'Data assegnazione',
            Cell: ({ row }) => format(new Date(row.original.assignation_date), "yyyy-MM-dd"),
            size: 25
          },
          {
            accessorKey: 'Business_Line',
            header: 'Tipo di Contratto',
            size: 150
          },
          {
            accessorKey: 'branch_desc',
            header: 'Filiale',
            size: 25
          },
          {
            accessorKey: 'professional_category_desc',
            header: 'Categoria Professionale',
            size: 25
          },
          {
            accessorKey: 'subsector_desc',
            header: 'Sottosettore',
            size: 25
          },
          {
            accessorKey: 'sector_desc',
            header: 'Settore',
            size: 25
          },
          {
            accessorKey: 'client_desc',
            header: 'Cliente',
            size: 100
          },
        ],
        []
      )
        const data: Ordini[] = ordini

        const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        muiTableContainerProps: {sx: { maxHeight: '500px' } },

    });

    return (
      <div>
        {ordini.length || timeE?
        <div style={{marginTop:"2%",marginBottom:"15%"}}>
            <MaterialReactTable table={table}></MaterialReactTable>
        </div>
        :
        <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Caricamento storico ordini...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
      }
      </div>
    )
}

export default Ordini