import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import LoaderComp from "../../../loader/Loader";
import EmailJob from "../Match/Email/EmailJob";
import EmailCandidate from "../../DirectMatching/Match/Email/Email";
import IQG from "../../DirectMatching/Match/IQG/IQG";
import CardDetailsJobRev from "./CardDetails/CardDetailsJobRev";
import { useMsal } from "@azure/msal-react";


function RealTimeRevMatch(props: any) {
  const [matches, setMatches] = useState([])
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [isDetails, setDetails] = useState(false);
  const [email, setEmail] = useState(false);
  const [jobID, setJobID] = useState("");
  const [matchDetails, setMatchDetails] = useState<any>({});
  const [jobDetails, setJobDetails] = useState({});
  const [emailJ, setEmailJ] = useState(false);
  const [iqg, setIqg] = useState(false);
  const [headersNew, setHeadersNew] = useState<any>({});
  const [explain, setExplain] = useState<any>({})
  const [dropdown, setDropdown] = useState(null);
  const [jobId,setJobId] = useState("")

  const { accounts } = useMsal();

  const findJob = async () => {
        let headersTemp:any = {}
        let expTemp:any = {}
        props.headers.forEach((value:any) => {
          if (value === 'jobTitle') {
    
            headersTemp[value] = 'Esperienza / Interesse candidato'
          }
    
          else if (value === 'city') {
            headersTemp[value] = 'Città candidato'
          }
    
          else if (value === 'maxRadius') {
            headersTemp[value] ='Distanza massima'
          }
    
          else if (value === 'skills') {
            headersTemp[value] ='Skill'
          }
    
          else if (value === 'education') {
            headersTemp[value] ='Education'
          }
    
          else if (value === 'language') {
            headersTemp[value] ='Lingue'
          }
    
          else if (value === 'license') {
            headersTemp[value] ='Patenti'
          }

          else if (value === 'interest') {
                headersTemp[value] ='Interesse candidato'
              }
        })
        setHeadersNew(headersTemp)
        fetch(`/api/realtime/embeddingsearchreverse?username=${accounts[0]['username']}`, {
          method: 'POST',
          headers: { 'Accept': 'application/json',
                    "Content-Type": "application/json"},
          body: JSON.stringify(props.dataSearch)})
          .then(response => response.json())
          .then(function(data) {
            setMatches(data);
            if (data.length === 0) {
              setTimeElapsed(true);
            }
            for (let i=0; i<=Math.min(4,data.length-1); i++) { 
              fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                method: 'POST',
                headers: { 'Accept': 'application/json',
                          "Content-Type": "application/json"},
                body: JSON.stringify({cv:"",jobDescription:"",motivo_match:data[i].Motivo_Match})})
                .then(response => response.json())
                .then(function(explain) {
                  expTemp[data[i].offer_id] = explain['spiegazione']
                  setExplain(expTemp)
                })
            }
          })      
    }


  const getBack = () => {
        props.setIsMatchingRealRevResult(false);
  }

  const generateQuestion = (match:any) => {
    console.log(match["professional_category"])
    setMatchDetails(match);
    setIqg(true);
  }

  const getDetails = (match:any) => {
    setJobID(match['offer_id']);
    setMatchDetails(match);
    setDetails(true);
  }

  const toggleDropdown = async(id:any, match:any) => {
    setDropdown((prev) => {
      if (prev === id) {
        return null
      }
      else {
        return id
      }
    });
    let expTemp:any = explain
    if (!(match.offer_id in explain)) {
     fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                method: 'POST',
                headers: { 'Accept': 'application/json',
                          "Content-Type": "application/json"},
                body: JSON.stringify({cv:"",jobDescription:"",motivo_match:match.Motivo_Match})})
                .then(response => response.json())
                .then(function(explain) {
                  expTemp[match.offer_id] = explain['spiegazione']
                  setExplain(expTemp)
                })
          } 
  };

  const toggleCheck = (id:any) => {
    let partial = props.checkList;
    if (props.checkList.includes(id)) {
      partial = partial.filter(function(e:any) { return e !== id })
      props.setCheckList(partial)
      
    }
    else {
      props.setCheckList([...props.checkList,id])
    }

    console.log(props.checkList)
  };

  const backDetails = () => {
    setDetails(false);
  }

  const generateEmail = (match: any) => {
        setMatchDetails({
        'professional_category':match['professional_category'],
        'Motivo_Match':match['Motivo_Match'],
        'Distanza_km':match['distance'],
        'work_city':match['workcity'],
        });
    setJobId(match.offer_id)
    setEmail(true);
  }

  const generateEmailToJob = (match:any) => {
    setMatchDetails(match);
    setJobDetails({'Motivo_Match':match['Motivo_Match'],'Distanza_km':match['distance']});
    setJobId(match.offer_id)
    setEmailJ(true);
  }


  useEffect(() => {
    findJob();
  }, [])


  return (
    <div>
        <div>
    </div>
      {(emailJ)?
        <EmailCandidate email={emailJ} setEmail={setEmailJ} candidate={jobDetails} candidateId={"realtime"} jobId={jobId} category={matchDetails["professional_category"]} workCity={props.dataSearch["city"]}></EmailCandidate>
      :
      <div>
      {(email) ?
      <div>
        <EmailJob email={email} setEmail={setEmail} job={matchDetails} candidateId={"realtime"} jobId={jobId}></EmailJob>
      </div>
      :
      <div>
        {(isDetails) 
        ?
          <div>
            <div>
              <CardDetailsJobRev jobID={jobID} job={matchDetails} setIsMatchingRealRevResult={props.setIsMatchingRealRevResult} setDetails={setDetails}></CardDetailsJobRev>
            </div>
          </div>
        : 
        <div>
        {(iqg) ?
          <div>
            <IQG category={matchDetails["professional_category"]} iqg={iqg} setIqg={setIqg} candidateId={"realtime"} jobId={jobId}></IQG>    
          </div>
          :
        <div>
          <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
            </div>
          </div>
          {(matches.length > 0 || timeElapsed)? 
            <div className="row-main">
              <div className="column-table-direct">
                {props.headers.filter((item:any) => {
                let toExclude = [
                ]
                if (item === 'jobTitle' || item === 'interest') {
                  if (props.dataSearch[item] === null) {
                    toExclude.push(item)
                  }
                }
                else {
                  if (props.dataSearch[item].length == 0) {
                    toExclude.push(item)
                  }
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
                <div className="inline-div-super-dir">
                    <h2 className="test-h3">{headersNew[header]}</h2>
                    <h5>{props.dataSearch[header]}</h5>
                </div>
                ))}
              </div>
              <div className="sovra-container-direct">
              {(timeElapsed &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                  {matches.map((match: any) => (
                    <div key={match.offer_id} className="candidate-card-dir">
                      <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(match.offer_id)} checked={props.checkList.includes(match.offer_id)}></input>
                      <label className="label-for-check">Visualizzato</label>
                      <div className="sub-container-lr">
                        <div className="section-infos-right-dir">
                          <p><strong>ID Offerta: </strong>{match.offer_id}</p>
                          <p><strong>Cat. Prof.: </strong>{match.professional_category}</p>
                          <p><strong>Job Title: </strong>{match.job_title}</p>
                          {(match.client_desc && <p><strong>Cliente: </strong>{match.client_desc}</p>)}
                          <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {props.dataSearch['city']} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {match.workcity} </strong>pari a {Math.round(Number(match.distance))} KM</p>
                        </div>
                        <div className="section-infos-left">
                      <p
                            className= {
                              match.announcement_type === "OFFER" 
                                                        ? "blue-tag" 
                                                        : match.announcement_type === "ORDER"
                                                        ? "orange-tag"
                                                        : ""
                            }
                            >
                            {match.announcement_type}
                          </p>
                      </div>
                      </div>
                      <div id="dropdown-full">
                        <div 
                          key={match.offer_id}
                          className={`dropdown-slot ${dropdown === match.offer_id ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(match.offer_id,match)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === match.offer_id ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === match.offer_id ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === match.offer_id ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                              {dropdown === match.offer_id? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === match.offer_id ? '' : 'match-reason-hidden'}`}>
                        <span className={`match-reason ${dropdown === match.offer_id ? '' : 'match-reason-hidden'}`}>
                            {(explain[match.offer_id] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                        </span>
                          {dropdown === match.offer_id? explain[match.offer_id]: ""}
                        </div>
                        <div className="row-button">
                          <button className="cta-red-mat" onClick={() => getDetails(match)}>
                            {"Dettagli"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmail(match)}>
                            {"Email Candidato"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateQuestion(match)}>
                            {"Genera Domande"}
                          </button>
                          <button className="cta-red-mat" onClick={() => generateEmailToJob(match)}>
                            {"Email Cliente"}
                          </button>
                        </div>
                      </div>
                  </div>
                ))}
              </div>
            </div>
          : 
          <div className="div-center">
            <div>
              <h3>Ricerca posizioni lavorative in corso</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
          </div>}
          </div>
          }
          </div>
          }
          </div>
          }
          </div>
          }
          <div style={{textAlign:"center"}}>
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
            </div>
      </div>
  )

}

export default RealTimeRevMatch;