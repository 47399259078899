import './App.css';
import Main from "./components/content/Main";
import Header from "./components/nav/Header"
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginPage from './components/auth/Login';


function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: "#333",
          color: "#fff",
        }}
        />
        <AuthenticatedTemplate>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage></LoginPage>
        </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
