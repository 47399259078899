import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import "./Match.css"
import LoaderComp from "../../../loader/Loader";
import { useIsDirectResult } from "../../../context/isDirectMatchingResult";
import CardDetails from "./CardDetails/CardDetails";
import IQG from "./IQG/IQG";
import EmailCandidate from "./Email/Email";
import EmailJob from "../../ReverseMatching/Match/Email/EmailJob";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";


function TableMatches(props: any) {
  const [matches, setMatches] = useState([])
  const [timeElapsed, setTimeElapsed] = useState(false);
  const { toggleView } = useIsDirectResult();
  const [isDetails, setDetails] = useState(false);
  const [iqg, setIqg] = useState(false);
  const [email, setEmail] = useState(false);
  const [emailC, setEmailC] = useState(false);
  const [codFisc, setCodFisc] = useState("");
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [candidateId, setCandidateId] = useState("");
  const [cvs, setCvs] = useState("")
  const [urlPDF, setUrlPDF] = useState("");
  const [matchDetails, setMatchDetails] = useState({});
  const [isAI, setIsAI] = useState(false)
  const [headersNew, setHeadersNew] = useState<any>({})
  const [explain, setExplain] = useState<any>({})
  const [dropdown, setDropdown] = useState(null);
  const [quit, setQuit] = useState(false);

  const { accounts } = useMsal();

  const findCandidate = async (announcement_id:any) => {
    let expTemp:any = {}
    let headersTemp:any = {}
    console.log(announcement_id === "")
    props.headers.forEach((value:any) => {
      if (value === 'announcement_id') {

        headersTemp[value] = 'Job ID'
      }

      else if (value === 'announcement_type') {
        headersTemp[value] = 'Tipologia'
      }

      else if (value === 'business_line') {
        headersTemp[value] ='Tipo di Contratto'
      }

      else if (value === 'work_order_number') {
        headersTemp[value] ='Numero Ordine'
      }

      else if (value === 'offer_number') {
        headersTemp[value] ='Numero Offerta'
      }

      else if (value === 'branch') {
        headersTemp[value] ='CDC'
      }

      else if (value === 'contract_duration') {
        headersTemp[value] ='Durata contratto'
      }

      else if (value === 'professional_category') {
        headersTemp[value] ='Categoria professionale'
      }

      else if (value === 'client_desc') {
        headersTemp[value] ='Cliente'
      }

      else if (value === 'work_city') {
        headersTemp[value] ='Città offerta'
      }

      else if (value === 'Categoria_protetta') {
        headersTemp[value] ='Categoria protetta'
      }
    })
    setHeadersNew(headersTemp)
    fetch(`/api/databricks/jobdesc?announcement_id=${announcement_id}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json' } })
      .then(response => response.json())
      .then(function(data) {
        if (announcement_id === "") {
          setMatches([])
        }

        else {
          fetch(`/api/databricks/findCandidate?announcement_id=${announcement_id}&username=${accounts[0]['username']}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(result) {
              setMatches(result)
              if (result.length === 0) {
                setTimeElapsed(true);
              }
              for (let i=0; i<=Math.min(4,result.length-1); i++) {
                fetch(`/api/databricks/cvcand?associate_id=${result[i].Codice_Fiscale}`, {
                  method: 'GET',
                  headers: { 'Accept': 'application/json' } })
                  .then(response => response.json())
                  .then(function(cv) {
                    if (cv['cv'] !== "" && data['job'] !== "") {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                   "Content-Type": "application/json"
                         },
                        body: JSON.stringify({cv:cv['cv'],jobDescription:data['job'],motivo_match:result[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(explain) {
                          if (explain['spiegazione']) {
                            expTemp[result[i].Codice_Fiscale] = explain['spiegazione']
                          }
                          else {
                            fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                              method: 'POST',
                              headers: { 'Accept': 'application/json',
                                         "Content-Type": "application/json",
                                         'X-User-Id': accounts[0]['username']
                               },
                              body: JSON.stringify({cv:cv['cv'],jobDescription:"",motivo_match:result[i].Motivo_Match}) })
                              .then(response => response.json())
                              .then(function(explainNoJob) {
                                expTemp[result[i].Codice_Fiscale] = explainNoJob['spiegazione']
                              })
                          }
                        })
                    }
                    else {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                  "Content-Type": "application/json",
                                'X-User-Id': accounts[0]['username']},
                        body: JSON.stringify({cv:"",jobDescription:"",motivo_match:result[i].Motivo_Match})})
                        .then(response => response.json())
                        .then(function(lastRes) {
                          expTemp[result[i].Codice_Fiscale] = lastRes['spiegazione']
                        })
                    }
                    setExplain(expTemp)
                  })
                  .catch(error => console.error('Error fetching data:', error));
                  
              }
            })
            .catch(error => console.error('Error fetching data:', error));
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const getBack = () => {
    setQuit(true);
    toggleView(true);
  }

  const generateQuestion = () => {
    console.log(props.row["professional_category"])
    setIqg(true);
  }

  const getDetails = (fiscale:any) => {
    setCodFisc(fiscale);
    setDetails(true);
  }

  const generateEmailToJob = (candidate: any) => {
    setCandidateDetails(candidate);
    setCandidateId(candidate.Codice_Fiscale)
    setEmail(true);
  }

  const generateEmailToCandidate = (row:any,candidate:any) => {
    setMatchDetails({'professional_category':row['professional_category'],
                    'Motivo_Match':candidate['Motivo_Match'],
                    'Distanza_km':candidate['Distanza_km'],
                    'work_city':row['work_city']
    });
    setCandidateId(candidate.Codice_Fiscale)
    setEmailC(true);
  }

  useEffect(() => {
    console.log(props.row)
    findCandidate(props.announcement_id);

    
  }, [])

  const copyToClipboard = async (codFiscString: any, jobId: any) => {
        fetch(`/api/databricks/copy?username=${accounts[0]['username']}&associate_id=${codFiscString}&job_id=${jobId}`, {
          method: 'GET',
          headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
          .then(response => response.json())
          .then(data => console.log(data['result']))
          .catch(error => console.error('Error fetching data:', error));
          navigator.clipboard.writeText(codFiscString)
          toast.success('CF copiato!', {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
      }



  const toggleDropdown = async (id:any, candidate:any) => {
    setDropdown((prev) => {
      if (prev === id) {
        return null
      }
      else {
        return id
      }
    });
    if (!(candidate.Codice_Fiscale in explain)) {
      let expTemp:any = explain
      fetch(`/api/databricks/jobdesc?announcement_id=${props.announcement_id}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(job) {
          fetch(`/api/databricks/cvcand?associate_id=${candidate.Codice_Fiscale}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(cv) {
              if (cv['cv'] !== "" && job['job'] !== "") {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                             "Content-Type": "application/json",
                             'X-User-Id': accounts[0]['username']
                   },
                  body: JSON.stringify({cv:cv['cv'],jobDescription:job['job'],motivo_match:candidate.Motivo_Match}) })
                  .then(response => response.json())
                  .then(function(explain) {
                    if (explain['spiegazione']) {
                      expTemp[candidate.Codice_Fiscale] = explain['spiegazione']
                    }
                    else {
                      fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                        method: 'POST',
                        headers: { 'Accept': 'application/json',
                                   "Content-Type": "application/json",
                                   'X-User-Id': accounts[0]['username']
                         },
                        body: JSON.stringify({cv:['cv'],jobDescription:"",motivo_match:candidate.Motivo_Match}) })
                        .then(response => response.json())
                        .then(function(explainNoJob) {
                          expTemp[candidate.Codice_Fiscale] = explainNoJob['spiegazione']
                        })
                    }
                  })
              }
              else {
                fetch(`/api/openai/spiegazione?username=${accounts[0]['username']}`, {
                  method: 'POST',
                  headers: { 'Accept': 'application/json',
                            "Content-Type": "application/json",
                          'X-User-Id': accounts[0]['username']},
                  body: JSON.stringify({cv:"",jobDescription:"",motivo_match:candidate.Motivo_Match})})
                  .then(response => response.json())
                  .then(function(lastRes) {
                    expTemp[candidate.Codice_Fiscale] = lastRes['spiegazione']
                  })
              }
              setExplain(expTemp)
              })
            })
    }
  };

  const toggleCheck = (id:any) => {
    let partial = props.checkList;
    if (props.checkList.includes(id)) {
      partial = partial.filter(function(e:any) { return e !== id })
      props.setCheckList(partial)
      
    }
    else {
      props.setCheckList([...props.checkList,id])
    }

    console.log(props.checkList)
  };


  return (
  <div>
      {(emailC)?
        <EmailJob email={emailC} setEmail={setEmailC} job={matchDetails} jobId={props.announcement_id} candidateId={candidateId}></EmailJob>
      :
    <div>
    {(email) ?
    <div>
      <EmailCandidate email={email} setEmail={setEmail} candidate={candidateDetails} jobId={props.announcement_id} candidateId={candidateId} category={props.row["professional_category"]} workCity={props.row["work_city"]}></EmailCandidate>
    </div>
    :
    <div>
      {(isDetails) 
      ?
      <div>
      <div>
          <CardDetails cvs={cvs} setCvs={setCvs} codFisc={codFisc} urlPDF={urlPDF} setUrlPDF={setUrlPDF} userId={props.userId} isAI = {isAI} setIsAI = {setIsAI} isDetails = {isDetails} setDetails = {setDetails} toggleView = {toggleView} jobId={props.announcement_id}>  </CardDetails>
        </div>
      </div>
      : 
      <div>
        {(iqg) ?
        <div>
          <IQG category={props.row["professional_category"]} iqg={iqg} setIqg={setIqg} jobId={props.announcement_id} candidateId={candidateId}></IQG>    
        </div>
        :
        
        <div>
          <div>
            <div className='home-btn-slot-5'>
              <i className='bx bx-home bx-sm bx-border' id='home-btn' onClick={getBack}>
                <span className='home-btn-text-5'>
                Torna alla schermata Home
                </span>
              </i>
            </div>
          </div>
        {(matches.length > 0 || timeElapsed)? 
          <div className="row-main">
            <div className="column-table-direct">
              {props.headers.filter((item:any) => {
                let toExclude = [
                  "url",
                  "reception_date",
                  "contract_beginning_date",
                  "job_owner",
                  "job_title",
                  "work_province",
                  "skills","educations",
                  "languages",
                  "cards"
                ]
                if (props.row['work_order_number'] !== null) {
                  toExclude.push('offer_number')
                }
                else if (props.row['offer_number'] !== null) {
                  toExclude.push('work_order_number')
                }
                if (props.row[item] === null) {
                  toExclude.push(item)
                }
                if (!toExclude
              .includes(item)
            ) return item
          
            }).map((header:any) => (
              <div className="inline-div-super-dir">
                  <h2 className="test-h3">{headersNew[header]}</h2>
                  <h5>{props.row[header]}</h5>
              </div>
              ))}
            </div>
            
            <div className="sovra-container-direct">
            {(timeElapsed &&
                  <div className="no-result">
                    <strong><p>Nessun risultato trovato</p></strong>
                  </div>
                  )}
                {matches.map((candidate: any) => (
                  <div id={candidate.ID_Univoco} className="candidate-card-dir">
                    <input type="checkbox" className="check-with-label" id="idinput" onClick={() => toggleCheck(candidate.ID_Univoco)} checked={props.checkList.includes(candidate.ID_Univoco)}></input>
                    <label className="label-for-check">Visualizzato</label>
                    <div className="sub-container-lr">
                    <div className="section-infos-right-dir">
                      <p><strong>Nome:</strong> {candidate.Nome} {candidate.Cognome}</p>
                      <p><strong>CF:</strong> {candidate.Codice_Fiscale} <button className="cta-red-mat" onClick={() => copyToClipboard(candidate.Codice_Fiscale,props.announcement_id)}>Copia CF</button></p>
                      <p><strong>Distanza: Domicilio</strong><strong style={{ color: '#DA291C' }}> {candidate.Comune_Domicilio} </strong>- <strong>Lavoro</strong><strong style={{ color: '#DA291C' }}> {props.row["work_city"]} </strong>pari a {Math.round(Number(candidate.Distanza_km))} KM</p>
                      <p><strong>Interesse:</strong> {candidate.Experience_Top}</p>
                      <p><strong>Max Step Pipeline:</strong> {candidate.max_step_pipeline_overall}</p>
                      <p><strong>Collaboratore in disponibilità:</strong> {candidate.sl_bench}</p>
                      </div>
                    <div className="section-infos-left">
                    <p
                          className= {
                            candidate.Stato_Candidato === "Certificato" 
                                                      ? "green-tag" 
                                                      : candidate.Stato_Candidato === "Da valutare"
                                                      ? "orange-tag"
                                                      : candidate.Stato_Candidato === "Non idoneo"
                                                      ? "red-tag"
                                                      : ""
                          }
                          >
                          {candidate.Stato_Candidato}
                        </p>
                    </div>
                    </div>
                    <div id="dropdown-full">
                        <div 
                          key={candidate.ID_Univoco}
                          className={`dropdown-slot ${dropdown === candidate.ID_Univoco ? 'dropdown-slot-active' : ''}`} 
                          onClick={() => toggleDropdown(candidate.ID_Univoco,candidate)}>
                          <i 
                            className={`bx bx-sm bx-chevron-right ${dropdown === candidate.ID_Univoco  ? 'arrow-icon-rotated' : ''}`} 
                            id='arrow-icon'
                            style={{
                              transform: dropdown === candidate.ID_Univoco ? 'rotate(90deg)' : 'rotate(0deg)',
                              transition: 'transform 1s ease'
                            }}
                          ></i>

                            <span className={`dropdown-text ${dropdown === candidate.ID_Univoco ? 'dropdown-text-hidden': 'dropdown-text'}`}>
                                {dropdown === candidate.ID_Univoco? "Torna indietro": "Info Match"}
                            </span>

                        </div>
                        <div className={`match-reason ${dropdown === candidate.ID_Univoco ? '' : 'match-reason-hidden'}`}>
                          <span className={`match-reason ${dropdown === candidate.ID_Univoco ? '' : 'match-reason-hidden'}`}>
                            {(explain[candidate.Codice_Fiscale] !== undefined) ? "" : <LoaderComp></LoaderComp>}
                          </span>
                          {dropdown === candidate.ID_Univoco? explain[candidate.Codice_Fiscale]: ""}
                        </div>
                        <div className="row-button">
                        <button className="cta-red-mat" onClick={() => getDetails(candidate.Codice_Fiscale)}>
                          {"Dettagli Candidato"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateEmailToCandidate(props.row,candidate)}>
                          {"Email Candidato"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateQuestion()}>
                          {"Genera Domande"}
                        </button>
                        <button className="cta-red-mat" onClick={() => generateEmailToJob(candidate)}>
                          {"Email Cliente"}
                        </button>
                      </div>
                      </div>
                </div>
              ))}
          </div>
          </div>
          
        : 
        <div className="div-center">
          <div >
            <h3> Ricerca candidati in corso</h3>
          </div>
          <div style={{width:"100px", margin:"auto"}}>
            <LoaderComp></LoaderComp>
          </div >
        </div>}
        </div>
        }
        </div>
        }
        </div>
        }
      </div>
      }
      <div style={{textAlign:"center", fontSize: "medium"}}>
        <h6>
        <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
        Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
        </h6>
      </div>
    </div>
  )
}

export default TableMatches;