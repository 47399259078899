import { useMsal } from '@azure/msal-react';
import { on } from 'events';
import { useEffect, useState } from 'react';

function LoginPage() {
  const { instance} = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };


  
  useEffect(() => {
    initializeSignIn();
  }, [])

  return (
    <>
      <div></div>
    </>
  );
};

export default LoginPage;