import { useEffect, useState } from "react"
import { _get } from "../../../../../apiClient"

import './IQG.css'
import { toast } from "react-toastify";
import LoaderComp from "../../../../loader/Loader";
import { useMsal } from "@azure/msal-react";

function IQG(props:any) {
    const [questions, setQuestions] = useState([])

    const { accounts } = useMsal();

    const getQuestions = () => {
        fetch(`/api/openai/question?professional_category=${props.category}&candidate=${props.candidateId}&username=${accounts[0]['username']}&job=${props.jobId}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(data) {
                setQuestions(data['domande'])
            })
      }

    const copyToClipboard = async (questionsString: string) => {
        navigator.clipboard.writeText(questionsString)
        toast.success('Domande copiate!', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }

    const backQuestion = () => {
    props.setIqg(false);
    }

    useEffect(() => {
    getQuestions();
    }, [])

    return (
    <div>
        <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-copy bx-sm bx-border' id='home-btn-4' onClick={() => {copyToClipboard(questions.toString())}}>
                    <span className='home-btn-text-2'>
                    Copia Domande
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backQuestion}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
        {(questions.length)
        ?
        <div>
        <div className="spacing">
            <pre>
                <p className="format-p">{questions}</p>
            </pre>
        </div>
        </div>
        :
        <div className="div-center">
            <div>
                <h3> Generazione domande in corso...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
        }    
    </div>
    )

    
}

export default IQG