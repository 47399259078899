import "./ReverseMatching.css";
import TableJobs from "./Candidate/Candidate"
import { useState } from "react";
import RealTimeRev from "./RealTimeRev/RealtimeRev";
import TableCandidate from "./Candidate/Candidate";
import { useIsMatchingRealTimeRev } from "../../context/isMatchingRealTimeRev";

const ReverseMatching = () => {
  const { isMatchingRealTimeRev, toggleViewRealRev } = useIsMatchingRealTimeRev();
  return (
    <div>
        <div className="text-center">
            <h1 className="mb0 desktop">GPT Talent <span style={{ color: "red" }}> Placer </span> </h1>
            <p className="mt0 desktop">
            </p>
        </div>
        {isMatchingRealTimeRev? <TableCandidate></TableCandidate> : <RealTimeRev ></RealTimeRev>}
    </div>
  );
}

export default ReverseMatching








;
