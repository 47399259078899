import { useEffect, useState } from "react"
import { _get } from "../../../../../../apiClient";
import './CV.css'
import LoaderComp from "../../../../../loader/Loader";
import { Buffer } from "buffer";


function CV(props:any) {
    const [timeE, setTimeE] = useState(false)
    
    const getCVS = async () => {
      fetch(`/api/realtime/getCVs?associate_id=${props.codFisc}&userId=PIT`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(data) {
          if (typeof data["data"] == "string" && data["data"] !=="vuoto") {
            props.setUrlPDF(data["data"]);
            props.setIsAI(true);
          }

          else if (data["data"] === "vuoto") {
            props.setUrlPDF("");
            setTimeE(true);
          }

          else {
            const buffer = Buffer.from(data["data"]);
            const base64String = buffer.toString('base64');  
            props.setUrlPDF(base64String);
          }
        } )
        .catch(error => console.error('Error fetching data:', error));
    }

      useEffect(() => {
        if (props.urlPDF === "" && !timeE) {
          getCVS();
        }
      }, [])

      return (
        <div>
          {(props.urlPDF != "") ?
            <div className="spacing">
              {(props.isAI) ? <pre>
                  <p className="format-p">{props.urlPDF}</p>
              </pre>
              :
              <div className="pdf-div">
                <iframe className="format-frame" src={`data:application/pdf;base64,${props.urlPDF}#toolbar=0`}/>
              </div>
              }
            </div>
            :
            <div>
              {(timeE) ?
              <div className="div-center">
              <div style={{marginTop:"2%"}}>
                  <h3>Nessun CV Trovato</h3>
              </div>
              </div>
            :
            <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Scaricamento CV...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
            </div>
            }
          </div>
            }
        </div> 
      )
}

export default CV

