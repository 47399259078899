import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
  } from "react";
  
  interface RealTimeDirectInputFieldsContextType {
    selectedOptionJob: any;
    selectedOptionBranch: any;
    selectedOptionEducation: Array<any>;
    selectedOptionSkill: Array<any>;
    selectedOptionLanguage: Array<any>;
    selectedOptionLicense: Array<any>;
    maxRadius: number;
    jobTitleData: string[];
    cityData: string[];
    skillsData: string[];
    educationData: string[];
    languageData: string[];
    licenseData: string[];
    setSelectedOptionJob:Dispatch<SetStateAction<any>>;
    setSelectedOptionBranch:Dispatch<SetStateAction<any>>;
    setSelectedOptionEducation:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionSkill:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionLanguage:Dispatch<SetStateAction<any[]>>;
    setSelectedOptionLicense:Dispatch<SetStateAction<any[]>>;
    setMaxRadius:Dispatch<SetStateAction<number>>;
    setJobTitleData:Dispatch<SetStateAction<any[]>>;
    setCityData:Dispatch<SetStateAction<any[]>>;
    setSkillsData:Dispatch<SetStateAction<any[]>>;
    setEducationData:Dispatch<SetStateAction<any[]>>;
    setLanguageData:Dispatch<SetStateAction<any[]>>;
    setLicenseData:Dispatch<SetStateAction<any[]>>;
    resetAllFields: () => void;
  }
  
  const RealTimeDirectInputFieldsContext = createContext<
  RealTimeDirectInputFieldsContextType | undefined
  >(undefined);
  
  export const useRealTimeDirectInputFieldsContext = () =>
    useContext(
        RealTimeDirectInputFieldsContext,
    ) as RealTimeDirectInputFieldsContextType;
  
  export const RealTimeDirectInputFieldsProvider: React.FC<{
    children: ReactNode;
  }> = ({ children }) => {
    const [jobTitleData, setJobTitleData] = useState<string[]>([]);
    const [cityData, setCityData] = useState<string[]>([]);
    const [skillsData, setSkillsData] = useState<string[]>([]);
    const [educationData, setEducationData] = useState<string[]>([]);
    const [languageData, setLanguageData] = useState<string[]>([]);
    const [licenseData, setLicenseData] = useState<string[]>([]);
    const[maxRadius, setMaxRadius] = useState<number>(25);
    const [selectedOptionJob, setSelectedOptionJob] = useState<any>([]);
    const [selectedOptionBranch, setSelectedOptionBranch] = useState<any>([]);
    const [selectedOptionEducation, setSelectedOptionEducation] = useState<any[]>([]);
    const [selectedOptionSkill, setSelectedOptionSkill] = useState<any[]>([]);
    const [selectedOptionLanguage, setSelectedOptionLanguage] = useState<any[]>([]);
    const [selectedOptionLicense, setSelectedOptionLicense] = useState<any[]>([]);

  
    const resetAllFields = () => {
      setSelectedOptionJob([]);
      setSelectedOptionBranch([]);
      setSelectedOptionEducation([]);
      setSelectedOptionSkill([]);
      setSelectedOptionLanguage([]);
      setSelectedOptionLicense([]);
      setMaxRadius(25);
    };
  
    const contextValues: RealTimeDirectInputFieldsContextType = {
      jobTitleData,
      cityData,
      skillsData,
      educationData,
      languageData,
      licenseData,
      maxRadius,
      selectedOptionJob,
      selectedOptionBranch,
      selectedOptionSkill,
      selectedOptionEducation,
      selectedOptionLanguage,
      selectedOptionLicense,
      setSelectedOptionJob,
      setSelectedOptionBranch,
      setSelectedOptionSkill,
      setSelectedOptionEducation,
      setSelectedOptionLanguage,
      setSelectedOptionLicense,
      setJobTitleData,
      setCityData,
      setSkillsData,
      setEducationData,
      setLanguageData,
      setLicenseData,
      setMaxRadius,
      resetAllFields,
    };
  
    return (
      <RealTimeDirectInputFieldsContext.Provider value={contextValues}>
        {children}
      </RealTimeDirectInputFieldsContext.Provider>
    );
  };
  