import { useEffect, useState } from "react";
import { _get } from "../../../../../apiClient";
import LoaderComp from "../../../../loader/Loader";
import { toast } from "react-toastify";
import './Email.css'
import { useMsal } from "@azure/msal-react";

function EmailCandidate(props: any) {
    const [emails, setEmails] = useState([])

    const { accounts } = useMsal();

    const backEmail = () => {
        props.setEmail(false);
    } 

    const copyToClipboard = async (emailString: string) => {
        navigator.clipboard.writeText(emailString)
        toast.success('Email copiata!', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }

    const getEmails = () => {
        if (emails.length === 0) {
            fetch(`/api/openai/email?professional_category=${props.category}&Motivo_Match=${props.candidate["Motivo_Match"]}&Distanza_km=${props.candidate["Distanza_km"]}&home_city=${props.workCity}&candidate=${props.candidateId}&username=${accounts[0]['username']}&job=${props.jobId}`, {
                method: 'GET',
                headers: { 'Accept': 'application/json' } })
                .then(response => response.json())
                .then(function(data) {
                    setEmails(data['email'])
                })
        }
      }

      useEffect(() => {
        getEmails();
        }, [])

    console.log(props.candidate)

    return (
        <div>
            <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-copy bx-sm bx-border' id='home-btn-4' onClick={() => {copyToClipboard(emails.toString())}}>
                    <span className='home-btn-text-2'>
                    {"Copia Email"}
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backEmail}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
        {(emails.length)
        ?
        <div>
        <div className="spacing-email">
            <pre>
                <p className="format-p-email">{emails}</p>
            </pre>
        </div>
            
        </div>
        :
        <div className="div-center">
            <div>
                <h3> Generazione email in corso...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
        }    
    </div>
    )
}

export default EmailCandidate