import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { _get } from '../../../../../../apiClient';
import LoaderComp from '../../../../../loader/Loader';
import { format } from "date-fns";
import { useMsal } from '@azure/msal-react';

function JobInfo (props:any) {
    const [job, setJob] = useState([])
    const [timeE, setTimeE] = useState(false)

    const { accounts } = useMsal();

    const getJobInfo = async () => {
      fetch(`/api/databricks/jobInfo?announcement_id=${props.job_id}&associate_id=${props.codFisc}&username=${accounts[0]['username']}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' } })
        .then(response => response.json())
        .then(function(data) {
          if (data.length === 0) {
            setTimeE(true);
          }
          setJob(data)
        })
      }

      useEffect(() => {
        getJobInfo();
      }, [])

    interface Job {
        associateid: string;
        job_id: string;
        announcement_type: string;
        type_business_line: string;
        professional_category: string;
        distance_km: number;
        skills: string;
        educations: string;
        cards: string;
        languages: string;
        branch_desc:string;
        work_order_number: number;
        offer_number: number;
        job_owner: string;
        reception_date: Date;
        end_of_validity_date:Date;
        contract_beginning_date: Date;
        client_desc: string;
        job_title: string;
        work_city: string;
        work_province: string;
        url: string;
        Categoria_protetta: string
      }
    
      const columns = useMemo<MRT_ColumnDef<Job>[]>(
        () => [
          {
            accessorKey: 'associateid',
            header: 'ID Candidato',
            size: 100
          },
          {
            accessorKey: 'job_id',
            header: 'ID Offerta',
            size: 25
          },
          {
            accessorKey: 'announcement_type',
            header: 'Tipologia',
            size: 100
          },
          {
            accessorKey: 'type_business_line',
            header: 'Tipo di Contratto',
            size: 100
          },
          {
            accessorKey: 'professional_category',
            header: 'Categoria Professionale',
            size: 100
          },
          {
            accessorKey: 'distance_km',
            header: 'Distanza (km)',
            size: 100
          },
          {
            accessorKey: 'skills',
            header: 'Skill Richieste',
            size: 100
          },

          {
            accessorKey: 'educations',
            header: 'Education Richieste',
            size: 25
          },
          {
            accessorKey: 'cards',
            header: 'Patenti Richieste',
            size: 25
          },
          {
            accessorKey: 'languages',
            header: 'Lingue Richieste',
            size: 100
          },
          {
            accessorKey: 'branch_desc',
            header: 'Filiale',
            size: 100
          },
          {
            accessorKey: 'work_order_number',
            header: 'Numero Ordine',
            size: 100
          },
          {
            accessorKey: 'offer_number',
            header: 'Numero Offerta',
            size: 100
          },
          {
            accessorKey: 'job_owner',
            header: 'Job Owner',
            size: 100
          },
          {
            accessorKey: 'reception_date',
            header: 'Data ricezione/pubblicazione',
            Cell: ({ row }) => format(new Date(row.original.reception_date), "yyyy-MM-dd"),
            size: 150
          },
          {
            accessorKey: 'end_of_validity_date',
            header: 'Data di scadenza',
            Cell: ({ row }) => format(new Date(row.original.end_of_validity_date), "yyyy-MM-dd"),
            size: 100
          },
          {
            accessorKey: 'contract_beginning_date',
            header: 'Data inizio contratto',
            Cell: ({ row }) => format(new Date(row.original.contract_beginning_date), "yyyy-MM-dd"),
            size: 100
          },
          {
            accessorKey: 'client_desc',
            header: 'Cliente',
            size: 100
          },
          {
            accessorKey: 'job_title',
            header: 'Job Title',
            size: 100
          },
          {
            accessorKey: 'work_city',
            header: 'Città di lavoro',
            size: 100
          },
          {
            accessorKey: 'work_province',
            header: 'Provincia di lavoro',
            size: 100
          },
          {
            accessorKey: 'url',
            header: 'Url',
            Cell: ({ row }) => <a href={row.original.url}>{row.original.url}</a>,
            size: 150
          },
          {
            accessorKey: 'Categoria_protetta',
            header: 'Categoria protetta',
            size: 100
          },

        ],
        []
      )
        const data: Job[] = job

        const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        muiTableContainerProps: {sx: { maxHeight: '500px' } },

    });

    return (
      <div>
        {job.length || timeE?
        <div style={{marginTop:"2%",marginBottom:"10%"}}>
            <MaterialReactTable table={table}></MaterialReactTable>
        </div>
        :
        <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Caricamento informazioni offerta...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
      }
      </div>
    )
}

export default JobInfo