import { useEffect, useState } from "react";

import JobInfo from "../../Match/CardDetails/Buttons/JobInfo";
import JobInfoReal from "./Buttons/JobInfoReal";

function CardDetailsJobRev(props: any) {
    const [jobInfo, setJobInfo] = useState(false);
    const [ordini, setOrdini] = useState(false);
    const [interviste, setInterviste] = useState(false);
    const [cv, setCv] = useState(false);

    const getInfojob = async() => {
        setJobInfo(true);
    }

    const backDetails = () => {
        props.setDetails(false);
    }
    
    const getBack = () => {
        props.setIsMatchingRealRevResult(false);
    }

    useEffect(() => {
        setJobInfo(true);
      }, [])

    return (
        <div>
            <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-home bx-sm bx-border' id='home-btn-4' onClick={getBack}>
                    <span className='home-btn-text-2'>
                    Torna alla schermata Home
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backDetails}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
            <div className="center-div">
                <button className="cta-red-mat" onClick={getInfojob}>
                                {"Info Offerta"}
                </button>
            </div>
            
            <JobInfoReal job_id={props.jobID}></JobInfoReal>

            </div>
        
    )
}

export default CardDetailsJobRev;