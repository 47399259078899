import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
  } from "react";
  
  interface CheckboxOption {
    label: string;
    checked: boolean;
  }
  
  interface DirectMatchingInputFieldsContextType {
    jobTitle: string;
    setJobTitle: Dispatch<SetStateAction<string>>;
    city: string;
    setCity: Dispatch<SetStateAction<string>>;
    saveAllFields: () => void;
    resetAllFields: () => void;
  }
  
  const DirectMatchingInputFieldsContext = createContext<
    DirectMatchingInputFieldsContextType | undefined
  >(undefined);
  
  export const useDirectMatchingInputFieldsContext = () =>
    useContext(
      DirectMatchingInputFieldsContext,
    ) as DirectMatchingInputFieldsContextType;
  
  export const DirectMatchingInputFieldsProvider: React.FC<{
    children: ReactNode;
  }> = ({ children }) => {
    const [jobTitle, setJobTitle] = useState<string>("");
    const [city, setCity] = useState<string>("");

  
    const resetAllFields = () => {
      setJobTitle("");
      setCity("");
    };
  
    const saveAllFields = () => {
      localStorage.setItem(
        "directMatchingFields",
        JSON.stringify({
          jobTitle,
          city,
        }),
      );
    };
  
    const contextValues: DirectMatchingInputFieldsContextType = {
      jobTitle,
      setJobTitle,
      city,
      setCity,
      saveAllFields,
      resetAllFields,
    };
  
    return (
      <DirectMatchingInputFieldsContext.Provider value={contextValues}>
        {children}
      </DirectMatchingInputFieldsContext.Provider>
    );
  };
  