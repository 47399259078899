import { _get, _post } from "../../apiClient";
import "react-toastify/dist/ReactToastify.css";
import "./Main.css";
import { useIsDirectMatching } from "../context/isDirectMatching";
import DirectMatching from "./DirectMatching/DirectMatching";
import ReverseMatching from "./ReverseMatching/ReverseMatching";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import UnAuth from "../auth/notAuth";


  function Main() {
    const [isList, setIsList] = useState(true);
    const { accounts } = useMsal();

    useEffect(() => {
      fetch(`/api/databricks/profiling?username=${accounts[0]['username']}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json','X-User-Id': accounts[0]['username'] } })
        .then(response => response.json())
        .then(data => setIsList(data['result']))
        .catch(error => console.error('Error fetching data:', error));
    }, [])

    const { isDirectMatching } = useIsDirectMatching();
    return (
      <main>
      {!isList ?
        <div>
          <UnAuth></UnAuth>
        </div>
  
        :
        <div className="container">
          {isDirectMatching ? 
          <DirectMatching /> : <ReverseMatching />}
        </div>
        }
      </main>
      )
  }

  export default Main;