import { createContext, useState, useContext, ReactNode } from "react";

interface IsDirectMatchingContextType {
  isDirectMatching: boolean;
  toggleView: () => void;
}

const IsDirectMatchingContext = createContext<
  IsDirectMatchingContextType | undefined
>(undefined);

export const useIsDirectMatching = () => {
  const context = useContext(IsDirectMatchingContext);
  if (!context) {
    throw new Error(
      "useIsDirectMatching must be used within an IsDirectMatchingProvider",
    );
  }
  return context;
};

export const IsDirectMatchingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_DIRECT_MATCHING =
    process.env.REACT_APP_IS_DIRECT_MATCHING_DEFAULT ?? "true";
  const [isDirectMatching, setIsDirectMatching] = useState(
    IS_DIRECT_MATCHING === "true",
  );

  const toggleView = () => {
    setIsDirectMatching(!isDirectMatching);
  };

  return (
    <IsDirectMatchingContext.Provider value={{ isDirectMatching, toggleView }}>
      {children}
    </IsDirectMatchingContext.Provider>
  );
};
