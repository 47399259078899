import { useEffect, useState } from "react";
import Ordini from "../../../DirectMatching/Match/CardDetails/Buttons/Ordini";
import Interviste from "../../../DirectMatching/Match/CardDetails/Buttons/Interviste";
import CV from "../../../DirectMatching/Match/CardDetails/Buttons/CV";
import JobInfo from "./Buttons/JobInfo";
import "./CardDetailsJob.css";

function CardDetailsJob(props: any) {
    const [jobInfo, setJobInfo] = useState(false);
    const [ordini, setOrdini] = useState(false);
    const [interviste, setInterviste] = useState(false);
    const [cv, setCv] = useState(false);

    const getInfojob = async() => {
        setJobInfo(true);
        setOrdini(false);
        setInterviste(false);
        setCv(false);
    }

    const getOrdini = async() => {
        setJobInfo(false);
        setOrdini(true);
        setInterviste(false);
        setCv(false);
    }

    const getInterviste= async() => {
        setJobInfo(false);
        setOrdini(false);
        setInterviste(true);
        setCv(false);
    }

    const getCv = async() => {
        setJobInfo(false);
        setOrdini(false);
        setInterviste(false);
        setCv(true);
    }

    useEffect(() => {
        setJobInfo(true);
        setOrdini(false);
        setInterviste(false);
        setCv(false);
    }, [])

    const backDetails = () => {
        props.setDetails(false);
    }
    
    const getBack = () => {
        props.toggleView(true);
    }


    return (
        <div>
            <div id='CardDetailsSlot-4'>
            <div className='home-btn-slot-3'>
                <i className='bx bx-home bx-sm bx-border' id='home-btn-4' onClick={getBack}>
                    <span className='home-btn-text-2'>
                    Torna alla schermata Home
                    </span>
                </i>
            </div>
            <div className='home-btn-slot-4'>
                <i className='bx bx-sm bx-border' id='home-btn-2' onClick={backDetails}>
                    <span className='home-btn-text-2'>
                    {"◂ Torna ai Risultati"}
                    </span>
                </i>
            </div>
        </div>
            <div className="center-div">
                <button className="cta-red-mat" onClick={getInfojob}>
                                {"Info Offerta"}
                </button>
                <button className="cta-red-mat" onClick={getOrdini}>
                                {"Storico Ordini"}
                </button>
                <button className="cta-red-mat" onClick={getInterviste}>
                                {"Storico Interviste"}
                </button>
                <button className="cta-red-mat" onClick={getCv}>
                                {"CV Candidato"}
                </button>
            </div>
            
            {(jobInfo)?<JobInfo job_id={props.job['job_id']} codFisc={props.codFisc}></JobInfo>:
            <div>
                {(ordini)?<Ordini codFisc={props.codFisc}></Ordini>:
                <div>
                    {(interviste)?<Interviste codFisc={props.codFisc}></Interviste>:
                    <div>
                        {(cv && <CV cvs={props.cvs} setCvs={props.setCvs} codFisc={props.codFisc} urlPDF={props.urlPDF} setUrlPDF={props.setUrlPDF}></CV>)}
                    </div>
                }
                </div>
            }
            </div>
            }
        </div>
        
    )
}

export default CardDetailsJob;