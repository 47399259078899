import { createContext, useState, useContext, ReactNode } from "react";

interface IsMatchingRealTimeRevContextType {
  isMatchingRealTimeRev: boolean;
  toggleViewRealRev: () => void;
}

const IsMatchingRealTimeRevContext = createContext<
IsMatchingRealTimeRevContextType | undefined
>(undefined);

export const useIsMatchingRealTimeRev = () => {
  const context = useContext(IsMatchingRealTimeRevContext);
  if (!context) {
    throw new Error(
      "useIsMatchingRealTimeRev must be used within an IsMatchingRealTimeRevProvider",
    );
  }
  return context;
};

export const IsMatchingRealTimeRevProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_MATCHING_REAL_REV =
    process.env.REACT_APP_IS_MATCHING_REAL_REV_DEFAULT ?? "false";
  const [isMatchingRealTimeRev, setIsMatchingRealRev] = useState(
    IS_MATCHING_REAL_REV === "false",
  );

  const toggleViewRealRev = () => {
    setIsMatchingRealRev(!isMatchingRealTimeRev);
  };

  return (
    <IsMatchingRealTimeRevContext.Provider value={{ isMatchingRealTimeRev, toggleViewRealRev}}>
      {children}
    </IsMatchingRealTimeRevContext.Provider>
  );
};
