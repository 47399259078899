import { Dispatch, SetStateAction, useEffect, useRef } from "react"
import { _get } from "../../apiClient"

export default function usePopulateField(endpoint: string, callback: Dispatch<SetStateAction<any>>) {
    const wasCalled = useRef(false);
    useEffect(() => {
        if(wasCalled.current) return;
        fetch(`${endpoint}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(data => callback(data))
            .catch(error => console.error('Error fetching data:', error));
        // _get(endpoint).then((res) => {
        //     const { data } = res
        //     callback(data)
        // })
    }, [])
}