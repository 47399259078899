import { useEffect, useState } from "react";
import { _get, _post} from "../../../../apiClient";
import "./Candidate.css"
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { useIsReverseResult } from "../../../context/isReverseMatchingResult";
import TableMatches from "../Match/Match"
import LoaderComp from "../../../loader/Loader";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import SidebarRev from "../Sidebar/SidebarRev";
import { format } from "date-fns";
import { useIsMatchingRealTimeRev } from "../../../context/isMatchingRealTimeRev";

function TableCandidate(props: any) {
  const [candidates, setCandidates] = useState([])
  const [associateId, setAssociate] = useState("");
  const { isReverseResult, toggleView } = useIsReverseResult();
  const [row, setRow] = useState([]);
  const [headers, setHeaders] = useState({});
  
  const [timeElapsed, setTimeElapsed] = useState(false);

  const [sidebarOpen, setSideBarOpen] = useState(false);
  const { isMatchingRealTimeRev, toggleViewRealRev } = useIsMatchingRealTimeRev();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkList, setCheckList] = useState<any[]>([])

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const [activeContent, setActiveContent] = useState(
    isMatchingRealTimeRev ? "realtime" : "normal"
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const switchContent = () => {
    setActiveContent((prevContent) =>
      prevContent === "normal" ? "realtime" : "normal"
    );
    toggleViewRealRev();
    setIsDropdownOpen(false);
  };

  const setResult = (associate_id: any, row:any) => {
    setAssociate(associate_id)
    setRow(row);
    setHeaders(Object.keys(row))
    toggleView(false);
  }
  interface Candidate {
    Codice_Fiscale: string;
    Stato_Candidato: string;
    Stato_Collaboratore: string;
    last_certification_date: Date;
    name: string;
    surname: string;
    home_city: string;
    home_province: string;
    Classe_Eta: string;
    professional_categories_profile: string;
    last_5_group_prof_cat_applications: string;
    matters_known: string;
    regulated_trainings: string;
    languages: string;
    driving_licenses: string;
    last_5_clients: string;
    register_date: Date;
    last_activity_date: Date;
    has_disadvantage: string;
    CDC_DI: string;
    Categoria_protetta: Date;
    Type_handicap_percentage: string;
    sl_bench: string;
  }



  const columns = useMemo<MRT_ColumnDef<Candidate>[]>(
    () => [
      {
        accessorKey: 'Codice_Fiscale',
        header: 'Id Candidato',
        size: 100
      },
      {
        accessorKey: 'Stato_Candidato',
        header: 'Stato Candidato',
        size: 150
      },
      {
        accessorKey: 'Stato_Collaboratore',
        header: 'Stato Collaboratore',
        size: 25
      },
      {
        accessorKey: 'last_activity_date',
        header: "Data ultima attività",
        Cell: ({ row }) => format(new Date(row.original.last_activity_date), "yyyy-MM-dd"),
        size: 150
      },
      {
        accessorKey: 'last_certification_date',
        header: 'Ultima data certificazione',
        Cell: ({ row }) => format(new Date(row.original.last_certification_date), "yyyy-MM-dd"),
        size: 25
      },
      {
        accessorKey: 'home_city',
        header: 'Città domicilio',
        size: 25
      },
      {
        accessorKey: 'home_province',
        header: 'Provincia domicilio',
        size: 100
      },
      {
        accessorKey: 'Classe_Eta',
        header: 'Classe Età',
        size: 100
      },
      {
        accessorKey: 'professional_categories_profile',
        header: "Categoria professionale d'interesse",
        size: 150
      },
      {
        accessorKey: 'name',
        header: 'Nome',
        size: 200
      },
      {
        accessorKey: 'surname',
        header: 'Cognome',
        size: 200
      },
      {
        accessorKey: 'last_5_group_prof_cat_applications',
        header: "Ultime 5 categorie d'application",
        size: 150
      },
      {
        accessorKey: 'matters_known',
        header: "Skill",
        size: 150
      },
      {
        accessorKey: 'regulated_trainings',
        header: "Education",
        size: 150
      },
      {
        accessorKey: 'languages',
        header: "Lingue",
        size: 150
      },
      {
        accessorKey: 'driving_licenses',
        header: "Patenti",
        size: 150
      },
      {
        accessorKey: 'last_5_clients',
        header: "Ultimi 5 clienti",
        size: 150
      },
      {
        accessorKey: 'register_date',
        header: "Data registrazione",
        Cell: ({ row }) => format(new Date(row.original.register_date), "yyyy-MM-dd"),
        size: 150
      },
      {
        accessorKey: 'has_disadvantage',
        header: "Categoria svantaggiata",
        size: 150
      },
      {
        accessorKey: 'CDC_DI',
        header: "CDC D&I",
        size: 150
      },
      {
        accessorKey: 'Categoria_protetta',
        header: "Categoria Protetta (Intervista)",
        size: 150
      },
      {
        accessorKey: 'Type_handicap_percentage',
        header: "Handicaps",
        size: 150
      },
      {
        accessorKey: 'sl_bench',
        header: "Collaboratore in Disponibilità",
        size: 200
      },
    ],
    []
  )


  useEffect(() => {
    toggleView(true);
    fetch('/api/databricks/candidateread', {
      method: 'GET',
      headers: { 'Accept': 'application/json' } })
      .then(response => response.json())
      .then(data => setCandidates(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [])

  const data: Candidate[] = candidates

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: 'first',
    initialState: { density: 'compact' },
    enableStickyHeader: true,
    localization: {'actions':'Suggerimento'},
    muiTableContainerProps: {sx: { maxHeight: '500px' } },  
    renderRowActions: ({ row }) => (
      <div>
      <button type="submit" className="button-action-dir" onClick={() => setResult(row.original.Codice_Fiscale,row.original)}>
        <h4>MATCHING</h4>
      </button>
      </div>

  ),
  });

  return (
    <div>
      {isReverseResult ?
      <div style={{textAlign:"center"}}>
        <div>
            <div id="menu-toggle-mobile">
              <div
                className={`container-toggle-dropdown mobile ${
                  isDropdownOpen ? "active" : ""
                }`}
                onClick={toggleDropdown}
              >
                <div>
                  {activeContent === "realtime" ? (
                    <>
                      <h4 className="m0">Candidati disponibili</h4>
                      <p className="m0">Cerca tra i candidati disponibili</p>
                    </>
                  ) : (
                    <>
                      <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </>
                  )}
                </div>
                <img
                  width={12}
                  src={isDropdownOpen ? chevronUp : chevronDown}
                  alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
                  className="chevron-icon"
                />
              </div>
              {isDropdownOpen && (
                <div className="dropdown-menu-mobile">
                  {activeContent === "normal" ? (
                    <div onClick={switchContent}>
                      <h4 className="m0">Candidati disponibili</h4>
                      <p className="m0">Cerca tra i candidati disponibili</p>
                    </div>
                  ) : (
                    <div onClick={switchContent}>
                      <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                      <p className="m0">Seleziona le caratteristiche richieste</p>
                    </div>
                  )}
                </div>
              )}
            </div>
        </div>
        <div>
          {(candidates.length > 0 || timeElapsed) ? 
            <div>
              <SidebarRev isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} setJ={setCandidates} timeE={setTimeElapsed} />
              <div className="back">
                <MaterialReactTable table={table} />
              </div>
              <div className="spacing-div">
                <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
            </div> : <div className="div-center">
            <div>
              <h3> Caricamento Candidati</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
              <LoaderComp></LoaderComp>
            </div >
        </div>}
        </div>
      </div> 
      : <TableMatches associate_id={associateId} row={row} headers={headers} checkList={checkList} setCheckList={setCheckList}></TableMatches>}
    </div>
  )

}

export default TableCandidate;