import { createContext, useState, useContext, ReactNode } from "react";

interface IsDirectResultContextType {
  isDirectResult: boolean;
  toggleView: (bool: boolean) => void;
}

const IsDirectResultContext = createContext<
IsDirectResultContextType | undefined
>(undefined);

export const useIsDirectResult = () => {
  const context = useContext(IsDirectResultContext);
  if (!context) {
    throw new Error(
      "useIsDirectResult must be used within an IsDirectResultProvider",
    );
  }
  return context;
};

export const IsDirectResultProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_DIRECT_RESULT =
    process.env.REACT_APP_IS_DIRECT_RESULT_DEFAULT ?? "true";
  const [isDirectResult, setIsDirectResult] = useState(
    IS_DIRECT_RESULT === "true",
  );

  const toggleView = (bool: boolean) => {
    setIsDirectResult(bool);
  };

  return (
    <IsDirectResultContext.Provider value={{ isDirectResult, toggleView}}>
      {children}
    </IsDirectResultContext.Provider>
  );
};
