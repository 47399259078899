import "./Header.css";
import logo from "../../images/logo-adecco.png"
import iconItaly from "../../images/icon-flag-it.svg"
import { useState } from "react";
import { useIsDirectMatching } from "../context/isDirectMatching"
import chevronUp from "../../images/chevron-up.svg";
import chevronDown from "../../images/chevron-down.svg";
import { useIsMatchingRealTime } from "../context/isMatchingRealTime";
import { useIsMatchingRealTimeResult } from "../context/isMatchingRealTimeResult";

function Header() {
    const { isDirectMatching, toggleView } = useIsDirectMatching();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { isMatchingRealTime, toggleViewReal } = useIsMatchingRealTime();
    const switchContentReal = () => {
      toggleViewReal();
    };
    const [activeContent, setActiveContent] = useState(
      isDirectMatching ? "direct" : "reverse"
    );
  
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const switchContent = () => {
      setActiveContent((prevContent) =>
        prevContent === "reverse" ? "direct" : "reverse"
      );
      toggleView();
      setIsDropdownOpen(false);
    };

    const feedbackWindow = () => {
      window.location.href = 'https://forms.office.com/pages/responsepage.aspx?id=kcEK87S48kWam-VGbLkMLw4tGaTSiLFOgn-u9-xFJ-lUMFcwSkdSVVVLNTA1NFpYN0I0VElSVDVQUS4u&route=shorturl'
    }
  
    return (
      <div>
        <header>
          <img src={logo} alt="logo Adecco" />
            <div className="toggle-container">
                <div className="toggle-content-left">
                  <h4>GPT Talent Finder</h4>
                  <p>Trova il candidato giusto</p>
                </div>
                <div className="toggle-switch">
                <input
                    type="checkbox"
                    id="toggle"
                    className="toggle-input"
                    checked={!isDirectMatching}
                    onChange={switchContent}
                />
                <label htmlFor="toggle" className="toggle-label" />
                </div>
                <div className="toggle-content-right">
                <h4>GPT Talent Placer</h4>
                <p>Trova l’offerta di lavoro ideale</p>
                </div>
            </div>
            {/* <div className="toggle-container-dir-head">
              <div className="toggle-content-left-dir">
                <h4>Opportunità aperte</h4>
                <p>Cerca tra le opportunità disponibili</p>
              </div>
              <div className="toggle-switch-dir">
              <input
                  type="checkbox"
                  id="toggle-dir"
                  className="toggle-input-dir"
                  checked={!isMatchingRealTime}
                  onChange={toggleViewReal}
              />
              <label htmlFor="toggle-dir" className="toggle-label-dir" />
              </div>
              <div className="toggle-content-right-dir">
              <h4>Definisci l’offerta di lavoro</h4>
              <p>Seleziona le caratteristiche richieste</p>
              </div>
            </div> */}
          <button onClick={feedbackWindow} className="cta-red-header">
                  <h4 className="feedback-text">Lascia un feedback</h4>
        </button>
        </header>
        <div className="mobile">
          <div id="menu-toggle-mobile">
            <div
              className={`container-toggle-dropdown mobile ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={toggleDropdown}
            >
              <div>
                {activeContent === "reverse" ? (
                  <>
                    <h4 className="m0">GPT Talent Finder</h4>
                    <p className="m0">Trova il candidato giusto</p>
                  </>
                ) : (
                  <>
                    <h4 className="m0">GPT Talent Placer</h4>
                    <p className="m0">Trova l’offerta di lavoro ideale</p>
                  </>
                )}
              </div>
              <img
                width={12}
                src={isDropdownOpen ? chevronUp : chevronDown}
                alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
                className="chevron-icon"
              />
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu-mobile">
                {activeContent === "reverse" ? (
                  <div onClick={switchContent}>
                    <h4 className="m0">GPT Direct Matching</h4>
                    <p className="m0">Sto cercando un candidato</p>
                  </div>
                ) : (
                  <div onClick={switchContent}>
                    <h4 className="m0">GPT Reverse Matching</h4>
                    <p className="m0">Sto cercando un offerta</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  
  export default Header;