

function UnAuth() {


    return (
      <>
        <div>
          <p>Non sei abilitato all'uso di questo strumento</p>
        </div>
      </>
    );
  };
  
  export default UnAuth;