import { createContext, useState, useContext, ReactNode } from "react";

interface IsMatchingRealTimeResultType {
    isMatchingRealTimeResult: boolean;
  toggleView: (bool: boolean) => void;
}

const IsMatchingRealTimeResult = createContext<
IsMatchingRealTimeResultType | undefined
>(undefined);

export const useIsMatchingRealTimeResult = () => {
  const context = useContext(IsMatchingRealTimeResult);
  if (!context) {
    throw new Error(
      "useIsMatchingRealTimeResult must be used within an IsMatchingRealTimeResultProvider",
    );
  }
  return context;
};

export const IsMatchingRealTimeResultProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_MRT_RESULT =
    process.env.REACT_APP_IS_MRT_DEFAULT ?? "true";
  const [isMatchingRealTimeResult, setIsMatchingRealTimeResult] = useState(
    IS_MRT_RESULT === "false",
  );

  const toggleView = (bool: boolean) => {
    setIsMatchingRealTimeResult(bool);
  };

  return (
    <IsMatchingRealTimeResult.Provider value={{ isMatchingRealTimeResult, toggleView}}>
      {children}
    </IsMatchingRealTimeResult.Provider>
  );
};
