import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef} from 'material-react-table';
import { _get } from '../../../../../../apiClient';
import LoaderComp from '../../../../../loader/Loader';
import { useMsal } from '@azure/msal-react';

function Anagrafica (props:any) {
    const [anagrafiche, setAnagrafiche] = useState([])

    const { accounts } = useMsal();

    const getAnag = () => {
          fetch(`/api/databricks/anagrafica?associate_id=${props.codFisc}&username=${accounts[0]['username']}&job=${props.jobId}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' } })
            .then(response => response.json())
            .then(function(data) {
              setAnagrafiche(data)
            })
      }

      useEffect(() => {
        getAnag();
      }, [])

    interface Anagrafica {
        Codice_Fiscale: string;
        Stato_Candidato: string;
        name: string;
        surname: string;
        home_region: string;
        home_province: string;
        home_city: string;
        marketing_consent: string;
        professional_categories_profile: string;
        type_sex: string;
        languages: string;
        driving_licenses: String;
        Classe_Eta: string;
        regulated_trainings: string;
        matters_known: string;
        last_5_clients: string;
        last_5_group_prof_cat_applications: string;
        CDC_DI: string;
        Type_handicap_percentage: string;
        Stato_Collaboratore: string;
      }
    
      const columns = useMemo<MRT_ColumnDef<Anagrafica>[]>(
        () => [
          {
            accessorKey: 'Codice_Fiscale',
            header: 'Codice Fiscale',
            size: 100
          },
          {
            accessorKey: 'Stato_Candidato',
            header: 'Stato Candidato',
            size: 25
          },
          {
            accessorKey: 'name',
            header: 'Nome',
            size: 150
          },
          {
            accessorKey: 'surname',
            header: 'Cognome',
            size: 25
          },
          {
            accessorKey: 'home_region',
            header: 'Regione domicilio',
            size: 25
          },
          {
            accessorKey: 'home_province',
            header: 'Provincia domicilio',
            size: 25
          },
          {
            accessorKey: 'home_city',
            header: 'Comune domicilio',
            size: 25
          },
          {
            accessorKey: 'marketing_consent',
            header: 'Consenso marketing',
            size: 100
          },
          {
            accessorKey: 'professional_categories_profile',
            header: 'Categorie professionali',
            size: 100
          },
          {
            accessorKey: 'type_sex',
            header: 'Genere',
            size: 150
          },
          {
            accessorKey: 'languages',
            header: 'Lingue',
            size: 150
          },
          {
            accessorKey: 'driving_licenses',
            header: 'Patenti',
            size: 150
          },
          {
            accessorKey: 'Classe_Eta',
            header: 'Classe età',
            size: 150
          },
          {
            accessorKey: 'regulated_trainings',
            header: 'Education',
            size: 150
          },
          {
            accessorKey: 'matters_known',
            header: 'Skills',
            size: 150
          },
          {
            accessorKey: 'last_5_clients',
            header: 'Ultimi 5 clienti',
            size: 150
          },
          {
            accessorKey: 'last_5_group_prof_cat_applications',
            header: 'Ultime 5 categorie applicazioni',
            size: 150
          },
          {
            accessorKey: 'CDC_DI',
            header: 'CDC_D&I',
            size: 150
          },
          {
            accessorKey: 'Type_handicap_percentage',
            header: 'Handicaps',
            size: 150
          },
          {
            accessorKey: 'Stato_Collaboratore',
            header: 'Stato collaboratore',
            size: 150
          },
        ],
        []
      )
        const data: Anagrafica[] = anagrafiche

        const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableStickyHeader: true,
        muiTableContainerProps: {sx: { maxHeight: '500px' } },

    });

    return (
      <div>
        {anagrafiche.length?
        <div style={{marginTop:"2%",marginBottom:"15%"}}>
            <MaterialReactTable table={table}></MaterialReactTable>
        </div>
        :
        <div className="div-center">
            <div style={{marginTop:"2%"}}>
                <h3>Caricamento anagrafiche...</h3>
            </div>
            <div style={{width:"100px", margin:"auto"}}>
                <LoaderComp></LoaderComp>
            </div >
        </div>
      }
      </div>
    )
}

export default Anagrafica