import { useIsMatchingRealTime } from "../../../context/isMatchingRealTime";
import { useIsMatchingRealTimeResult } from "../../../context/isMatchingRealTimeResult";
import { useMemo, useState, useRef, ChangeEvent, useEffect } from "react";
import { _post } from "../../../../apiClient";
import usePopulateField from "../../../hooks/usePopulateFields";
import Select from 'react-select'
import RealtimeRevMatch from "../RealTimeRevMatch/RealtimeRevMatch";
import { toast } from "react-toastify";
import chevronUp from "../../../../images/chevron-up.svg";
import chevronDown from "../../../../images/chevron-down.svg";
import CreatableSelect from "react-select/creatable";
import { useIsMatchingRealTimeRev } from "../../../context/isMatchingRealTimeRev";
import { useRealTimeReverseInputFieldsContext } from "../../../context/RealTimeReverseInputFields";

const RealTimeRev = () => { 
  const { selectedOptionJob, 
    setSelectedOptionJob,
    selectedOptionBranch,
    setSelectedOptionBranch,
    selectedOptionEducation,
    setSelectedOptionEducation,
    selectedOptionSkill,
    setSelectedOptionSkill,
    selectedOptionLanguage,
    setSelectedOptionLanguage,
    selectedOptionLicense,
    setSelectedOptionLicense,
    maxRadius,
    setMaxRadius,
    jobTitleData,
    setJobTitleData,
    cityData,
    setCityData,
    skillsData,
    setSkillsData,
    educationData,
    setEducationData,
    languageData,
    setLanguageData,
    licenseData,
    setLicenseData,
    experienceData,
    setExperienceData,
    selectedOptionExperience,
    setSelectedOptionExperience,
    resetAllFields} = useRealTimeReverseInputFieldsContext();
    // const [jobTitleData, setJobTitleData] = useState<string[]>([]);
    // const [experienceData, setExperienceData] = useState<string[]>([]);
    // const [cityData, setCityData] = useState<string[]>([]);
    const [headers, setHeaders] = useState({});
    // const [skillsData, setSkillsData] = useState<string[]>([]);
    // const [educationData, setEducationData] = useState<string[]>([]);
    // const [languageData, setLanguageData] = useState<string[]>([]);
    // const [licenseData, setLicenseData] = useState<string[]>([]);
    const [dataPost, setDataPost] = useState<any>({});
    // const [selectedOptionJob, setSelectedOptionJob] = useState<any>("");
    // const [selectedOptionBranch, setSelectedOptionBranch] = useState<any>("");
    // const [selectedOptionEducation, setSelectedOptionEducation] = useState<any[]>([]);
    // const [selectedOptionSkill, setSelectedOptionSkill] = useState<any[]>([]);
    // const [selectedOptionLanguage, setSelectedOptionLanguage] = useState<any[]>([]);
    // const [selectedOptionLicense, setSelectedOptionLicense] = useState<any[]>([]);
    // const [selectedOptionExperience, setSelectedOptionExperience] = useState<any>("");;
    // const [maxRadius, setMaxRadius] = useState<number>(25);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { isMatchingRealTimeRev, toggleViewRealRev } = useIsMatchingRealTimeRev();
    const [isMatchingRealTimeRevResult, setIsMatchingRealRevResult] = useState(false);

    const [checkList, setCheckList] = useState<any[]>([])

    const [activeContent, setActiveContent] = useState(
      isMatchingRealTimeRev ? "realtime" : "normal"
    );
    const radiusInputRef = useRef<HTMLInputElement>(null);

    const handleRadiusChange = (event: ChangeEvent<HTMLInputElement>) => {
      setMaxRadius(Number(event.target.value));
    };

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const switchContent = () => {
      setActiveContent((prevContent) =>
        prevContent === "normal" ? "realtime" : "normal"
      );
      toggleViewRealRev()
      setIsDropdownOpen(false);
    };
  
    useEffect(() => {
      console.log(radiusInputRef.current)
      if (radiusInputRef.current) {
        const percentage = ((maxRadius - 0) / (200 - 0)) * 100;
        radiusInputRef.current.style.setProperty("--value", `${percentage}%`);
      }
    }, [maxRadius]);


    usePopulateField("/api/databricks/skills", setSkillsData);
    usePopulateField("/api/databricks/education", setEducationData);
    usePopulateField("/api/databricks/language", setLanguageData);
    usePopulateField("/api/databricks/license", setLicenseData);
    usePopulateField("/api/databricks/filterJob", setJobTitleData);
    usePopulateField("/api/databricks/filterJob", setExperienceData);
    usePopulateField("/api/databricks/filterCity", setCityData);
    

    const getBack = () => {
      toggleViewRealRev();
    }
  
    const handleTypeSelectJob = (e:any) => {
      setSelectedOptionJob(e ? e : null);

    };
  
    const handleTypeSelectBranch = (e:any) => {
      setSelectedOptionBranch(e ? e : null);
    };

    const handleTypeSelectSkill = (e:any) => {
      setSelectedOptionSkill(e ? e : null);
    };

    const handleTypeSelectEducation = (e:any) => {
      setSelectedOptionEducation(e ? e : null);
    };

    const handleTypeSelectLanguage = (e:any) => {
      setSelectedOptionLanguage(e ? e : null);
    };

    const handleTypeSelectLicense = (e:any) => {
      setSelectedOptionLicense(e ? e : null);
    };

    const handleTypeSelectExperience = (e:any) => {
        setSelectedOptionExperience(e ? e : null);
      };

    let dataSearchCheck = {"jobTitle":selectedOptionExperience, 
                      "city":selectedOptionBranch, 
                      "maxRadius":radiusInputRef.current?.value, 
                      "skills":selectedOptionSkill,
                      "education":selectedOptionEducation,
                      "language":selectedOptionLanguage,
                      "license":selectedOptionLicense,
                      "interest":selectedOptionJob}
                      

    const handleSearchCandidate = async() => {

      console.log(dataSearchCheck)

      let dataSearch:any = {}

      if ((dataSearchCheck['jobTitle'] === null || dataSearchCheck['jobTitle'] === "") && (dataSearchCheck['interest'] === null || dataSearchCheck['interest'] === "")){
        toast.error("Inserire Esperienza o Interesse Candidato",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }

      else if (dataSearchCheck['city'].length === 0 || dataSearchCheck['city'] === null){
        toast.error("Inserire Città Candidato",{
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          })
        return 
      }

      if (dataSearchCheck['jobTitle'] === null|| dataSearchCheck['jobTitle'] === ""){
        dataSearch['jobTitle'] = dataSearchCheck['interest'][0].value;
        dataSearch['interest'] = null
      }

      else if (dataSearchCheck['interest'] === null || dataSearchCheck['interest'] === ""){
        dataSearch['jobTitle'] = dataSearchCheck['jobTitle'].value;
        dataSearch['interest'] = null
      }

      else {
        dataSearch['jobTitle'] = dataSearchCheck['jobTitle'].value;
        dataSearch['interest'] = null
      }

      console.log(dataSearch)
    
      dataSearch['city'] = dataSearchCheck['city'].value;
      dataSearch['skills'] = dataSearchCheck['skills'].map(a => `${a.value}`);
      dataSearch['education'] = dataSearchCheck['education'].map(a => `${a.value}`);
      dataSearch['language'] = dataSearchCheck['language'].map(a => `${a.value}`);
      dataSearch['license'] = dataSearchCheck['license'].map(a => `${a.value}`);
      dataSearch['maxRadius'] = radiusInputRef.current?.value
      setDataPost(dataSearch)
      setHeaders(Object.keys(dataSearchCheck));
      setIsMatchingRealRevResult(true);
    }

  return (
    <div>
    <div>
    <div>
      {isMatchingRealTimeRevResult ? 
      <div>
        <div>
          <RealtimeRevMatch dataSearch={dataPost} headers={headers} setSelectedOptionJob={setSelectedOptionExperience} setSelectedOptionBranch={setSelectedOptionBranch} isMatchingRealTimeRevResult={isMatchingRealTimeRevResult} setIsMatchingRealRevResult={setIsMatchingRealRevResult} checkList={checkList} setCheckList={setCheckList}></RealtimeRevMatch>
        </div>
      </div>:
      <div>
        <div id="menu-toggle-mobile">
        <div
          className={`container-toggle-dropdown mobile ${
            isDropdownOpen ? "active" : ""
          }`}
          onClick={toggleDropdown}
        >
          <div>
            {activeContent === "realtime" ? (
              <>
                <h4 className="m0">Candidati disponibili</h4>
                <p className="m0">Cerca tra i candidati disponibili</p>
              </>
            ) : (
              <>
                <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </>
            )}
          </div>
          <img
            width={12}
            src={isDropdownOpen ? chevronUp : chevronDown}
            alt={isDropdownOpen ? "Chevron up" : "Chevron down"}
            className="chevron-icon"
          />
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu-mobile">
            {activeContent === "normal" ? (
              <div onClick={switchContent}>
                <h4 className="m0">Candidati disponibili</h4>
                <p className="m0">Cerca tra i candidati disponibili</p>
              </div>
            ) : (
              <div onClick={switchContent}>
                <h4 className="m0">Definisci le caratteristiche del candidato</h4>
                <p className="m0">Seleziona le caratteristiche richieste</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="slot-col">
      <table style={{width:"100%",textAlign:"center"}}>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
            <p className="bold">Esperienza Candidato <strong style={{ color: '#DA291C' }}>*</strong></p>
              <CreatableSelect 
              options={experienceData} 
              value={selectedOptionExperience}
              onChange={handleTypeSelectExperience}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Inserisci Esperienza Candidato"
              isDisabled={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Interesse Candidato <strong style={{ color: '#DA291C' }}>*</strong></p>
            <Select 
            isMulti
            options={jobTitleData} 
            value={selectedOptionJob}
            onChange={handleTypeSelectJob}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Interesse Candidato"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Lingue Candidato</p>
          <Select 
            options={languageData} 
            value={selectedOptionLanguage}
            isMulti
            onChange={handleTypeSelectLanguage}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Lingua"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Città <strong style={{ color: '#DA291C' }}>*</strong></p>
            <Select 
            options={cityData} 
            onChange={handleTypeSelectBranch}
            value={selectedOptionBranch}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Seleziona Città"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>
        <tr>
          <td style={{paddingBottom:"5%",width:"50%",paddingRight:"5%"}}>
          <p className="bold">Skill Candidato</p>
            <Select 
            options={skillsData} 
            value={selectedOptionSkill}
            isMulti
            onChange={handleTypeSelectSkill}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Skill"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
          <td style={{paddingBottom:"5%",width:"50%",paddingLeft:"5%"}}>
          <p className="bold">Education Candidato</p>
            <Select 
            options={educationData} 
            value={selectedOptionEducation}
            isMulti
            onChange={handleTypeSelectEducation}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Education"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </td>
        </tr>

        </table>
        <div className="filter">
          <p className="bold">Patenti Candidato</p>
          <div style={{marginBottom:"15%"}}>
            <Select 
            options={licenseData} 
            value={selectedOptionLicense}
            isMulti
            onChange={handleTypeSelectLicense}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleziona Patente"
            isDisabled={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            />
          </div>
          <p className="bold">Distanza</p>
          <div style={{marginTop:"5%",marginBottom:"20%"}}>
            <div id="radius-selector">
                <p className="bold"></p>
                <p>{maxRadius} km</p>
                <input
                  type="range"
                  id="radius"
                  name="radius"
                  min="0"
                  max="200"
                  step="5"
                  value={maxRadius}
                  onChange={handleRadiusChange}
                  ref={radiusInputRef}
                />
              </div>
          </div>
          <div className="button-position">
          <button className="cta-red-realtime" onClick={handleSearchCandidate}>
                {"Trova le offerte in linea"}
          </button>
          <button className="cta-red-realtime-right" onClick={resetAllFields}>
                {"Resetta i filtri"}
          </button>
          </div>
        </div>
        <div style={{marginTop:"10%"}}>
              <div style={{textAlign:"center"}}>
              <h6>
                <strong>Nota bene: Analizza in modo critico i risultati!</strong><br />
                Utilizziamo una soluzione di intelligenza artificiale generativa (Azure OpenAI) per fornirvi i suggerimenti, preparare le email (candidato e cliente) e generare le domande da sottoporre al candidato in fase di colloquio. Vi invitiamo a usare uno spirito critico e a modificare o completare qualsiasi risposta che riteniate errata o inappropriata.
                </h6>
              </div>
          </div>
      </div>
      </div>}
    </div>
    </div>
    </div>
  );
}

export default RealTimeRev;
