import { createContext, useState, useContext, ReactNode } from "react";

interface IsMatchingRealTimeContextType {
  isMatchingRealTime: boolean;
  toggleViewReal: () => void;
}

const IsMatchingRealTimeContext = createContext<
IsMatchingRealTimeContextType | undefined
>(undefined);

export const useIsMatchingRealTime = () => {
  const context = useContext(IsMatchingRealTimeContext);
  if (!context) {
    throw new Error(
      "useIsMatchingRealTime must be used within an IsMatchingRealTimeProvider",
    );
  }
  return context;
};

export const IsMatchingRealTimeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const IS_MATCHING_REAL =
    process.env.REACT_APP_IS_MATCHING_REAL_DEFAULT ?? "false";
  const [isMatchingRealTime, setIsMatchingReal] = useState(
    IS_MATCHING_REAL === "false",
  );

  const toggleViewReal = () => {
    setIsMatchingReal(!isMatchingRealTime);
  };

  return (
    <IsMatchingRealTimeContext.Provider value={{ isMatchingRealTime, toggleViewReal}}>
      {children}
    </IsMatchingRealTimeContext.Provider>
  );
};
